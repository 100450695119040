import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/jasperkamphuis/Work/gatsby-theme-recipes-master/packages/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const StyledParagraph = makeShortcode("StyledParagraph");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Image alt="Over" src="/images/about.jpg" position="right" mdxType="Image" />
    <h1>{`Over`}</h1>
    <StyledParagraph mdxType="StyledParagraph">Hallo, ik ben Jasper, een liefhebber van lekker eten en koken in de keuken. Er is echter één ding dat ik altijd vervelend heb gevonden bij het zoeken naar recepten online - die eindeloze scrolltochten door websites vol met hinderlijke reclame, waardoor je het eigenlijke recept nauwelijks kunt vinden.</StyledParagraph>
    <StyledParagraph mdxType="StyledParagraph">Dus, om mijn eigen kookavonturen te vereenvoudigen en om anderen te helpen die hetzelfde probleem delen, heb ik deze site gecreëerd - een plek waar ik mijn favoriete recepten opsla en deel. Maar dat is niet alles.</StyledParagraph>
    <StyledParagraph mdxType="StyledParagraph">Wat deze plek echt bijzonder maakt, is de mogelijkheid om ingrediënten af te vinken. Hoe vaak ben je begonnen met koken, om halverwege te ontdekken dat je een cruciaal ingrediënt mist? Met mijn site kun je eenvoudig aangeven welke ingrediënten je al in huis hebt en welke je nog moet halen, waardoor je kookervaring nog handiger wordt.</StyledParagraph>
    <StyledParagraph mdxType="StyledParagraph">Dus, welkom op mijn culinaire reis! Ik hoop dat je net zoveel plezier beleeft aan het ontdekken en delen van heerlijke recepten als ik.</StyledParagraph>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      